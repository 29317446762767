import config from '@/Framework/config';

export const NAME_MIN_LENGTH = 2;
export const NAME_MAX_LENGTH = 50;
export const FIRST_NAME_MIN_LENGTH = 2;
export const FIRST_NAME_MAX_LENGTH = 50;
export const LAST_NAME_MIN_LENGTH = 2;
export const LAST_NAME_MAX_LENGTH = 50;

class User {
  /**
   * Check if investor profile is full
   *
   * @param {Object} user
   *
   * @return {Boolean}
   */
  static isInvestorProfileFull(user) {
    return !!user.firstName && !!user.lastName && !!user.companyId && !!user.titleId;
  }

  /**
   * check if user is support user for current tenant
   * @param {String} email
   * @param{string} tenant
   * @return {Boolean}
   */
  static isSupportUserByTenant(tenant, email) {
    return Object.values(config.tenant).some(
      (product) => product.code === tenant && 'supportEmail' in product && product.supportEmail === email,
    );
  }

  /**
   * Converts Zhenya Kuzorov to Zhenya K. according to all rules we need
   *
   * @param firstName
   * @param lastName
   */
  static getFirstNameWithInitial(firstName, lastName) {
    if (!lastName || !lastName.length) {
      return firstName;
    }
    if (lastName.length === 1) {
      return `${ firstName } ${ lastName.toLocaleUpperCase() }.`;
    }

    return `${ firstName } ${ lastName[0].toLocaleUpperCase() }.`;
  }
}

export default User;
