import React, { createContext, useContext } from 'react';
import useRoadshow from './useRoadshow';

interface IProps {
  children: React.ReactNode,
}

export const RoadshowContext = createContext(null);

export function useRoadshowContext() {
  const context = useContext(RoadshowContext);
  if (!context) {
    throw new Error('useRoadshowContext must be used within a RoadshowContext');
  }
  return context;
}

function RoadshowContextProvider({ children }: IProps) {
  return (
    <RoadshowContext.Provider value={ useRoadshow() }>
      { children }
    </RoadshowContext.Provider>
  );
}

export default RoadshowContextProvider;
