import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PortalId from './constants';

interface IProp {
  children: React.ReactNode,
  usePortal?: boolean,
  portalId?: string,
}

const PortalWrp = ({
  children,
  portalId = PortalId.PORTAL_ID,
  usePortal = true,
}: IProp): React.ReactPortal | React.ReactElement => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (usePortal) {
      setMounted(true);
    }
    return () => setMounted(false);
  }, [portalId]);

  if (!usePortal) {
    return children as React.ReactElement;
  }

  return mounted ? ReactDOM.createPortal(children, document.getElementById(portalId)) : <></>;
};

export default PortalWrp;
