import { IValidationError } from './interfaces/ValidationError';

interface IParams {
  value: string,
  length: number,
  fieldName: string,
  fieldCaption?: string,
}

export const validateMinLength = (params: IParams): IValidationError => {
  if (params.value && params.value.trim().length < params.length) {
    return { [params.fieldName]: `${ params.fieldCaption || 'Length' } must contain at least ${ params.length } characters` };
  }
  return null;
};
