import { useState, useEffect } from 'react';
import get from 'lodash/get';
import { defaultColorSettings } from '@/dealroadshow/ui/components/Viewer/config';
import files from '@/Framework/url/filesUrl';
import { useRoadshowContext } from '@/dealroadshow/application/RoadshowContext';
import NotificationManager from '@/Framework/Notification/NotificationManager';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';
import { useDIContext } from '@/Framework/DI/DIContext';

const useCustomization = () => {
  const { container } = useDIContext();
  const { roadshow } = useRoadshowContext();

  const [viewerColorSettings, setViewerColorSettings] = useState<{ [key:string]: any }>(defaultColorSettings);
  const [customButtonEnabled, setCustomButtonEnabled] = useState(false);
  const [viewerCustomizationEnabled, setViewerCustomizationEnabled] = useState(false);
  const [isCustomizationDisclaimerEnabled, setCustomizationDisclaimerEnabled] = useState(false);
  const [isPending, setPending] = useState(true);

  useEffect(() => {
    if (roadshow.createTopButton) {
      setCustomButtonEnabled(roadshow.createTopButton);
      setViewerColorSettings({
        ...roadshow.createTopButtonSettings,
      });
    }
    if (roadshow.viewerCustomizationEnabled) {
      setViewerCustomizationEnabled(roadshow.viewerCustomizationEnabled);
    }
    if (roadshow?.viewerDisclaimerCustomizationEnabled) {
      setCustomizationDisclaimerEnabled(
        roadshow?.viewerDisclaimerCustomizationEnabled,
      );
    }
    if (roadshow.viewerCustomizationEnabled || roadshow?.viewerDisclaimerCustomizationEnabled) {
      setViewerColorSettings({
        ...roadshow.viewerCustomSettings,
        ...roadshow.createTopButtonSettings,
        viewerDisclaimerSettings: roadshow.viewerDisclaimerSettings,
      });
    }
  }, [roadshow]);

  const getCustomLogo = (): (string | null) => {
    if (!get(viewerColorSettings, 'viewerDisclaimerSettings.disclaimerLogo')) return null;
    return files.getFileUrl(viewerColorSettings.viewerDisclaimerSettings.disclaimerLogo.url);
  };

  const getViewerCustomSettings = async (entryCode: string): Promise<void> => {
    try {
      const roadshowRepository = container.get(RoadshowRepository);
      const response = await roadshowRepository.getViewerCustomSettings({ entryCode });
      setViewerColorSettings(response);
      if (response) {
        setViewerCustomizationEnabled(true);
        setCustomizationDisclaimerEnabled(!!response.viewerDisclaimerCustomizationEnabled);
      }
      setPending(false);
    } catch (e) {
      NotificationManager.error(getErrorMessage(e));
    }
  };

  return {
    setViewerColorSettings,
    setCustomButtonEnabled,
    setViewerCustomizationEnabled,
    colorSettings: viewerColorSettings,
    isCustomButtonEnabled: customButtonEnabled,
    isCustomPresentation: viewerCustomizationEnabled,
    isCustomizationDisclaimerEnabled,
    getCustomLogo,
    getViewerCustomSettings,
    isPending,
  };
};

export default useCustomization;
