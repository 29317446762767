import { IField } from '@/Framework/UI/Organisms/FinalForm/validators/interfaces/Field';
import { IRule } from '@/Framework/UI/Organisms/FinalForm/validators/interfaces/Rule';

export const combineFieldsWithValues = (
  fields: IField[],
  values: { [key: string] : string },
): {
  [key: string]: {
    displayName: string,
    fieldCaption: string,
    fieldName: string,
    value: string,
  },
} => fields.reduce((acc, { fieldName, fieldCaption }) => {
  const value = values[fieldName];
  const fieldWithValue = { [fieldName]: { fieldName, fieldCaption, value } };
  return { ...acc, ...fieldWithValue };
}, {});

export const groupValidators = (...validatorsList: any[]): {} => validatorsList.reduce(
  (validationSummary, currentValidator) => Object.assign(validationSummary, currentValidator),
  {},
);

/**
 * Use this helper, if you need to apply a validation to more than 1 field.
 */
interface IApplyValidatorToMultipleFields {
  fields: IField[],
  values: {},
  validator: (params: object) => {},
  getErrorKey?: (field: IField) => string,
}

export const applyValidatorToMultipleFields = (
  { fields, values, validator, getErrorKey }: IApplyValidatorToMultipleFields,
): object => fields.reduce(
  (errors, field) => {
    const validationResult = validator(
      {
        value: values[field.fieldName],
        ...field,
      },
    );

    if (validationResult) {
      errors[getErrorKey ? getErrorKey(field) : field.fieldName] = validationResult[field.fieldName];
    }

    return errors;
  },
  {},
);

export const fieldsArrayValidator = (
  rules: IRule[],
  fields: { collection: IField[] },
): ({ [key: string]: string })[] => {
  const membersArrayErrors = [];
  const inputErrors = {};

  for (let memberIndex = 0; memberIndex < fields.collection.length; memberIndex++) {
    let field = fields.collection[memberIndex];
    const memberErrors = {};
    rules.forEach((rule) => {
      rule.functionValidation.forEach((func) => {
        let error = func.name({
          ...func.params,
          fieldName: rule.fieldName,
          fieldCaption: rule.fieldCaption,
          value: field[rule.fieldName],
          allValue: field,
          allFieldsValue: fields,
          inputErrors,
        });

        if (!memberErrors[rule.fieldName] && error) {
          memberErrors[rule.fieldName] = error[rule.fieldName];
        }
      });
    });

    if (Object.keys(memberErrors).length) {
      membersArrayErrors[memberIndex] = memberErrors;
    }
  }

  return membersArrayErrors;
};
