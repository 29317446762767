import base from './baseUrl';

export default {
  /**
   * Get base files url
   *
   * @param {Array||String} uris
   *
   * @return {String||Array}
   */
  getBaseUrl(...uris) {
    return base.getApiUrl('/files', ...uris);
  },

  /**
   * Get file url
   *
   * @param {String||Array} uris
   *
   * @return {String}
   */
  getFileUrl(...uris) {
    return this.getBaseUrl(...uris);
  },
};
