import { Dependencies } from 'constitute';
import { Subscription } from '@dealroadshow/socket-frontend-sdk';
import { Success } from '@dealroadshow/json-rpc-dispatcher';
import SocketClient from '@/dealroadshow/application/DI/Socket/Client';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';
import { IFetchCollectionPayload } from '@/Framework/State/useFetchCollection';
import { callbackWrapper } from '@/Framework/DI/Providers/Socket/helpers/callbackWrapper';

interface IPropsPostInvestorQuestion {
  investorQuestions: [],
  userId: number,
  phone: {
    value: string,
    code: string,
  },
  slideNumber: number,
  roadshowId: string,
  isRetail: boolean,
  isViewerReviewMode: boolean,
}

@Dependencies(RpcDispatcher, SocketClient)

class AskAQuestionRepository {
  protected constructor(
    protected rpc: typeof RpcDispatcher,
    protected socket: typeof SocketClient,
    protected questionsSubscribe,
    protected chatSubscription,
  ) {}

  postInvestorQuestion = async ({
    investorQuestions,
    userId,
    phone,
    slideNumber,
    roadshowId,
    isRetail = false,
    isViewerReviewMode = false,
  }: IPropsPostInvestorQuestion) => {
    const request = new Request(
      isRetail ? 'roadshow.public.investor_post_question' : 'roadshow.investor_post_question',
      { roadshowId, investorQuestions, userId, slideNumber, phone: phone.value, isViewerReviewMode },
    );
    const response = await this.rpc.call<Success>(request);

    return response.getResult().payload;
  };

  /**
   * Get question list for questions table.
   * @param {Object} payload
   * @return {Promise<*>}
   */
  getQuestionsList = async (payload: IFetchCollectionPayload) => {
    const request = new Request('roadshow.investor_questions.get_questions', payload);
    const response = await this.rpc.call<Success>(request);

    return response.getResult().payload;
  };

  /**
   * Check does roadshow has questions
   * @param {String} roadshowId
   * @returns {Promise<boolean>}
   */
  isRoadshowHasQuestions = async (roadshowId: string): Promise<boolean> => {
    let req = new Request('roadshow.investor_questions.is_roadshow_has_questions', { roadshowId });
    let response = await this.rpc.call(req);

    return response.getResult().payload;
  };

  /**
   * Get question list for questions table.
   * @param {String} roadshowId
   * @return {Promise<*>}
   */
  getQuestionsCounter = async (roadshowId) => {
    const request = new Request('roadshow.investor_questions.get_questions_counters', { roadshowId });
    const response = await this.rpc.call<Success>(request);

    return response.getResult().payload;
  };

  /**
   * Change question status.
   * @param payload
   * @return {Promise<*>}
   */
  changeQuestionsStatus = async (payload) => {
    const request = new Request('roadshow.investor_questions.change_questions_status', payload);
    const response = await this.rpc.call<Success>(request);

    return response.getResult().payload;
  };

  /**
   * Change question status.
   * @param payload
   * @return {Promise<*>}
   */
  changeReadUnreadState = async (payload) => {
    const request = new Request('roadshow.investor_questions.set_question_read', payload);
    const response = await this.rpc.call<Success>(request);

    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   * @param onNewQuestion
   * @param onChangeStatus
   * @returns {Promise<object>}
   */
  subscribeToQuestions = async ({
    roadshowId,
    ssid,
  }, {
    onNewQuestion,
    onChangeStatus,
  }) => {
    this.questionsSubscribe = await this.socket.subscribe(
      new Request('roadshow.investor_questions.manager.sub_unsub', { roadshowId }, { ssid }),
    );

    this.questionsSubscribe.on('questions.new', callbackWrapper(onNewQuestion));
    this.questionsSubscribe.on('questions.status_changed', callbackWrapper(onChangeStatus));
  };

  unsubscribeFromQuestions = () => {
    if (this.questionsSubscribe instanceof Subscription) {
      return this.questionsSubscribe.cancel();
    }
    return Promise.resolve();
  };

  /**
   * @param {Object} payload
   * @returns {Promise<object>}
   */
  subscribeOnRoadshowUpdate = async (
    {
      roadshowId,
      isPublic = false,
      onShowButton,
    },
  ) => {
    this.chatSubscription = await this.socket.subscribe(
      new Request(
        isPublic ? 'roadshow.public.update.socket' : 'roadshow.update.socket',
        { roadshowId },
      ),
    );

    this.chatSubscription.on(isPublic ? 'roadshow.public.update.socket' : 'roadshow.update.socket', callbackWrapper(onShowButton));
  };

  unsubscribeOnRoadshowUpdate = () => {
    if (this.chatSubscription instanceof Subscription) {
      this.chatSubscription.cancel();
    }
  };
}

export default AskAQuestionRepository;
