import React, { createContext, useContext } from 'react';
import useCustomization from './useCustomization';

type CustomizationContextType = ReturnType<typeof useCustomization>;

export const CustomizationContext = createContext<CustomizationContextType>(null);

export function useCustomizationContext() {
  const context = useContext(CustomizationContext);
  if (!context) {
    throw new Error('useCustomizationContext must be used within a CustomizationContext');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

function CustomizationContextProvider({ children }: IProps) {
  return (
    <CustomizationContext.Provider value={ useCustomization() }>
      { children }
    </CustomizationContext.Provider>
  );
}

export default CustomizationContextProvider;
