const defaultColorDisclaimerSettings = {
  disclaimerBackgroundColor: '#A7A9AC',
  disclaimerTileColor: '#005288',
  disclaimerPrimaryTextColor: '#ffffff',
  disclaimerButtonColor: '#5B97D7',
  disclaimerButtonTextColor: '#ffffff',
  disclaimerAccentColor: '#000000',
  customMessage: null,
  disclaimerSecondaryTextColor: '#939393',
  disclaimerLinkColor: '#3d9086',
  disclaimerLabelTextColor: '#8c8c8c',
  loginPrimaryText: 'Investor Login',
  loginSecondaryText: 'To access a Deal or Non-Deal Roadshow, login below',
  firstTimeFormPrimaryText: 'Welcome',
  firstTimeFormSecondaryText: 'This is your first time using Deal Roadshow. Please complete the following form.',
};

const defaultColorSettings = {
  presentationBackgroundColor: '#ebebeb',
  toolbarColor: '#fff',
  toolbarAccentColor: '#000',
  toolbarButtonsColor: '#4a76b6',
  buttonTextColor: '#fff',
  dealNameColor: '#333',
  rightPanelBackgroundColor: '#fff',
  rightPanelIconsColor: '#c5c5c5',
  rightPanelAccentColor: '#000',
  sectionHeaderColor: '#6c6c6c',
  customSectionHeaderBackgroundColor: '#eee',
  rightPanelTextAndDealRoadshowLogoColor: '#4a76b6',
  topButtonColor: '#355788',
  topButtonTextColor: '#fff',
  ...defaultColorDisclaimerSettings,
};

const customViewerClassNames = {
  customViewerLayoutContent: 'customViewerLayoutContent',
  customViewerSidebarItem: 'customViewerSidebarItem',
  customViewerSidebarItemActive: 'customViewerSidebarItemActive',
  customViewerSidebarItemLabel: 'customViewerSidebarItemLabel',
  customViewerSidebarHeader: 'customViewerSidebarHeader',
  customViewerPlayer: 'customViewerPlayer',
  customViewerToggleFloatingButton: 'customViewerToggleFloatingButton',
  customViewerSidebarWrapper: 'customViewerSidebarWrapper',
  customViewerSidebar: 'customViewerSidebar',
  customViewerSpinner: 'customViewerSpinner',
  customViewerToolbarInputContainer: 'customViewerToolbarInputContainer',
  customViewerMenuButtonContainer: 'customViewerMenuButtonContainer',
  customViewerTitleEllipsis: 'customViewerTitleEllipsis',
  customViewerToolbarIcon: 'customViewerToolbarIcon',
  customViewerToolbarText: 'customViewerToolbarText',
  customViewerDealName: 'customViewerDealName',
  customViewerToolbar: 'customViewerToolbar',
  customViewerCustomButton: 'customViewerCustomButton',
  customViewerSourceSwitcher: 'customViewerSourceSwitcher',
  customViewerLandscapeButton: 'customViewerLandscapeButton',
  customViewerSidebarButton: 'customViewerSidebarButton',
  customViewerSidebarButtonOutline: 'customViewerSidebarButtonOutline',
  customViewerSidebarExpandButton: 'customViewerSidebarExpandButton',
  customViewerSidebarInnerBorder: 'customViewerSidebarInnerBorder',
  customViewerSidebarText: 'customViewerSidebarText',
  customViewerSidebarLink: 'customViewerSidebarLink',
  customViewerSidebarLogo: 'customViewerSidebarLogo',
};

const viewerDialInNumbers = {
  liveVideoNumber: '+1 (202) 519-5118',
  presentationMediaNumber: '+1 (646) 437-8787',
};

export { defaultColorSettings, defaultColorDisclaimerSettings, customViewerClassNames, viewerDialInNumbers };
