import { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

interface IElementSize {
  width: number,
  height: number,
}
const useElementSize = (componentId: string): IElementSize => {
  const [elementHeight, setElementHeight] = useState<IElementSize>({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    const targetNode = document.getElementById(componentId);
    const callback = throttle(() => {
      setElementHeight({
        width: targetNode.offsetWidth,
        height: targetNode.offsetHeight,
      });
    }, 100);
    let observer;
    if (targetNode) {
      observer = new ResizeObserver(callback);
      observer.observe(targetNode);
    }
    return () => {
      observer?.disconnect();
    };
  }, []);
  return elementHeight;
};

export default useElementSize;
