export const selectTheme = (theme) => (
  {
    ...theme,
    borderRadius: 3,
    colors: {
      ...theme.colors,
      danger: '#DB1A33FF',
      neutral5: '#F3F3F3',
      primary25: '#F3F3F3FF',
      primary50: '#FFFFFF',
      primary75: '#FFFFFF',
      primary: '#8C8C8CFF',
      neutral50: '#8C8C8C',
    },
  }
);

export const customStyles = {
  control: (base, state) => (
    {
      ...base,
      height: 'auto',
      minHeight: 36,
      background: '#fffff',
      borderWidth: 1,
      zIndex: 0,
      borderRadius: state.menuIsOpen ? '3px 3px 0 0' : 3,
      borderColor: state.isFocused ? 'rgb(140,140,140)' : 'rgb(218,218,218)',
      boxShadow: state.isFocused ? '0 1px 5px rgba(0, 0, 0, 0.1)' : null,
      '&:hover': {
        borderColor: '#8c8c8c',
      },
    }),
  placeholder: (base) => ({
    ...base,
    color: '#8c8c8c',
  }),
  multiValue: (base) => ({
    ...base,
    height: 22,
    background: '#f9f9f9',
    borderRadius: 3,
  }),
  multiValueLabel: (base, { isDisabled }) => ({
    ...base,
    lineHeight: '16px',
    color: isDisabled ? '#8c8c8c' : '#616161',
    fontSize: '12px',
    padding: '3px 8px',
    paddingLeft: '8px',
  }),
  multiValueRemove: (base, { isDisabled }) => ({
    ...base,
    display: isDisabled ? 'none' : 'flex',
    background: '#f9f9f9',
    cursor: 'pointer',
    paddingLeft: '0',
    paddingRight: '8px',
    '& svg': {
      fill: '#4a76b6',
    },
    '&:hover': {
      background: 'transparent',
      '& svg': {
        fill: '#355788',
      },
    },
  }),
  groupHeading: (base, state) => {
    const isFirstChild = state.data.label === state.selectProps.options[0].label;
    return {
      ...base,
      fontSize: '10px',
      paddingLeft: '10px',
      paddingBottom: '6px',
      lineHeight: '8px',
      borderTop: isFirstChild ? '' : '1px solid #f3f3f3',
      paddingTop: isFirstChild ? '10px' : '18px',
      color: '#c0c0c0',
      letterSpacing: '1px',
    };
  },
  group: (base) => ({
    ...base,
    paddingBottom: '0px',
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    alignItems: 'center',
    '> input': {
      height: 'auto',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (base) => ({
    ...base,
    color: '#333',
    cursor: 'pointer',
    padding: '8px 10px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: 3,
    marginRight: 11,
    marginTop: !state.selectProps.menuIsOpen ? -4 : 4,
    border: !state.isFocused ? 'solid #c4c4c4' : 'solid #8c8c8c',
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    width: 8,
    height: 8,
    transition: 'all .2s ease',
    transform: !state.selectProps.menuIsOpen ? 'rotate(45deg)' : 'rotate(-135deg)',
  }),
  menu: (base, state) => ({
    ...base,
    marginTop: 0,
    marginBottom: 0,
    border: '1px solid #8c8c8c',
    borderRadius: (state.placement === 'top') ? '3px 3px 0 0' : '0 0 3px 3px',
    borderTop: (state.placement !== 'top') ? 0 : '1px solid #8c8c8c',
    boxShadow: '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 1px 5px hsl(0deg 0% 0% / 2%)',
    zIndex: 4,
  }),
  menuList: (base) => ({
    ...base,
    textAlign: 'left',
    padding: 0,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0 0 0 10px',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    overflowY: 'initial',
    overflowX: 'auto',
    textAlign: 'left',
  }),
  singleValue: (base, { isDisabled }) => ({
    ...base,
    marginLeft: 0,
    marginRight: 0,
    color: isDisabled ? '#8c8c8c' : '#2d2d2d',
  }),
};

export const customStylesSlim = {
  control: (base, state) => (
    {
      ...base,
      minHeight: 28,
      background: '#fffff',
      borderWidth: 1,
      zIndex: 1,
      borderRadius: state.menuIsOpen ? '3px 3px 0 0' : 3,
      borderColor: state.isFocused ? 'rgb(140,140,140)' : 'rgb(218,218,218)',
      boxShadow: state.isFocused ? '0 1px 5px rgba(0, 0, 0, 0.1)' : null,
      '&:hover': {
        borderColor: '#8c8c8c',
      },
    }),
  multiValue: (base) => ({
    ...base,
    background: '#f9f9f9',
    color: '#616161',
    borderRadius: 3,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0 0 0 10px',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    overflowX: 'auto',
    textAlign: 'left',
  }),
  singleValue: (base, { isDisabled }) => ({
    ...base,
    marginLeft: 0,
    marginRight: 0,
    color: isDisabled ? '#8c8c8c' : '#2d2d2d',
  }),
  multiValueRemove: (base) => ({
    ...base,
    background: '#f9f9f9',
    '& svg': {
      fill: '#4a76b6',
    },
    '&:hover': {
      background: 'transparent',
      '& svg': {
        fill: '#355788',
      },
    },
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    height: 22,
    alignItems: 'center',
    '> input': {
      height: 'auto',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (base) => ({
    ...base,
    color: '#333',
    cursor: 'pointer',
    padding: '8px 10px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: 3,
    marginRight: 11,
    marginTop: !state.selectProps.menuIsOpen ? -4 : 4,
    border: !state.isFocused ? 'solid #c4c4c4' : 'solid #8c8c8c',
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    width: 8,
    height: 8,
    transition: 'all .2s ease',
    transform: !state.selectProps.menuIsOpen ? 'rotate(45deg)' : 'rotate(-135deg)',
  }),
  menu: (base, state) => ({
    ...base,
    marginTop: 0,
    marginBottom: 0,
    border: '1px solid #8c8c8c',
    borderRadius: (state.placement === 'top') ? '3px 3px 0 0' : '0 0 3px 3px',
    borderTop: (state.placement !== 'top') ? 0 : '1px solid #8c8c8c',
    boxShadow: '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 1px 5px hsl(0deg 0% 0% / 2%)',
    zIndex: 4,
  }),
  menuList: (base) => ({
    ...base,
    textAlign: 'left',
    padding: 0,
  }),
};

export const customPaginatorStyles = {
  control: (base, state) => (
    {
      ...base,
      height: 30,
      minHeight: 30,
      lineHeight: '28px',
      background: '#fffff',
      borderWidth: 1,
      zIndex: 1,
      borderRadius: state.menuIsOpen ? '0 0 3px 3px' : 3,
      borderColor: state.isFocused ? 'rgb(140,140,140)' : 'rgb(218,218,218)',
      boxShadow: state.isFocused ? '0 1px 5px rgba(0, 0, 0, 0.1)' : null,
      '&:hover': {
        borderColor: '#8c8c8c',
      },
    }),
  input: (base) => ({
    ...base,
    margin: '0px',
    '> input': {
      height: 'auto',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (base) => ({
    ...base,
    color: '#333',
    cursor: 'pointer',
    padding: '8px 10px',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: 3,
    marginRight: 11,
    marginTop: !state.selectProps.menuIsOpen ? -4 : 4,
    border: !state.isFocused ? 'solid #c4c4c4' : 'solid #8c8c8c',
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    width: 8,
    height: 8,
    transition: 'all .2s ease',
    transform: !state.selectProps.menuIsOpen ? 'rotate(45deg)' : 'rotate(-135deg)',
  }),
  menu: (base, state) => ({
    ...base,
    marginTop: 0,
    marginBottom: 0,
    border: '1px solid #8c8c8c',
    borderRadius: (state.placement === 'top') ? '3px 3px 0 0' : '0 0 3px 3px',
    borderBottom: (state.placement === 'top') ? 0 : '1px solid #8c8c8c',
    boxShadow: '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 1px 5px hsl(0deg 0% 0% / 2%)',
    zIndex: 4,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0 0 0 10px',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    overflow: 'initial',
    textAlign: 'left',
  }),
  singleValue: (base) => ({
    ...base,
    marginLeft: 0,
    marginRight: 0,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
};
