export default function getOffset(el: HTMLElement, bodyEl?: HTMLElement): {
  top: number,
  left: number,
  viewportTop: number,
  viewportLeft: number,
  width: number,
  height: number,
} {
  let box = el.getBoundingClientRect();

  let body = bodyEl || document.body;
  let docEl = document.documentElement;

  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  let clientTop = docEl.clientTop || body.clientTop || 0;
  let clientLeft = docEl.clientLeft || body.clientLeft || 0;

  let top = box.top + scrollTop - clientTop;
  let left = box.left + scrollLeft - clientLeft;

  return {
    top,
    left,
    viewportTop: box.top,
    viewportLeft: box.left,
    width: el.offsetWidth,
    height: el.offsetHeight,
  };
}
