export enum RoadshowTypes {
  Slides = 'Slides',
  VideoOnly = 'Video Only',
  SlidesAndAudio = 'Slides + Audio',
  SlidesAndVideo = 'Slides + Video',
  LiveVideo = 'Live Video',
}

class RoadshowType {
  static isSlidesAndVideo(type: RoadshowTypes) {
    return type === RoadshowTypes.SlidesAndVideo;
  }

  static isSlidesAndAudio(type: RoadshowTypes) {
    return type === RoadshowTypes.SlidesAndAudio;
  }

  static isSlides(type: RoadshowTypes) {
    return type === RoadshowTypes.Slides;
  }

  static isVideoOnly(type: RoadshowTypes) {
    return type === RoadshowTypes.VideoOnly;
  }

  static isLiveVideo(type: RoadshowTypes) {
    return type === RoadshowTypes.LiveVideo;
  }

  static hasSkippingAhead(type: RoadshowTypes) {
    return [
      RoadshowTypes.Slides,
      RoadshowTypes.SlidesAndAudio,
      RoadshowTypes.SlidesAndVideo,
      RoadshowTypes.VideoOnly,
      RoadshowTypes.LiveVideo,
    ].includes(type);
  }

  static hasPresentationMedia(type: RoadshowTypes) {
    return [RoadshowTypes.SlidesAndAudio, RoadshowTypes.SlidesAndVideo, RoadshowTypes.VideoOnly].includes(type);
  }
}

export default RoadshowType;
