import React, { Component, createRef, ComponentType, RefObject } from 'react';
import ReactSelect, { components as ReactSelectComponents, createFilter } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import cn from 'classnames';
import throttle from 'lodash/throttle';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import Highlighter from 'react-highlight-words';
import getOffset from '@/Framework/dom/getOffset';
import { Icon, IconType, Tooltip, Spinner, FormError, FormField, InputLabel as Label } from '@dealroadshow/uikit';
import PortalWrp, { PortalId } from '@/Framework/UI/Templates/PortalWrp';
import { customStyles, customStylesSlim, selectTheme } from '../selectConfig';
import { SelectComponentsConfig } from 'react-select/dist/declarations/src/components';
import {
  ActionMeta,
  GroupBase,
  MenuPlacement,
  OnChangeValue,
  OptionsOrGroups,
} from 'react-select/dist/declarations/src/types';
import { Props } from 'react-select/dist/declarations/src/Select';
import { SingleValueProps } from 'react-select/dist/declarations/src/components/SingleValue';
import { MenuProps } from 'react-select/dist/declarations/src/components/Menu';
import { ValueContainerProps } from 'react-select/dist/declarations/src/components/containers';
import { MultiValueProps, MultiValueRemoveProps } from 'react-select/dist/declarations/src/components/MultiValue';
import { OptionProps } from 'react-select/dist/declarations/src/components/Option';

import styles from '../select.scss';

export interface IProps extends Partial<Props<any, boolean, GroupBase<any>>> {
  name?: string,
  placeholder?: string,
  selectedPlaceholderWhenOpen?: string,
  label?: string | React.ReactNode,
  value?: any,
  formFieldClassName?: string,
  className?: string,
  optionClassName?: string,
  selectedOptionClassName?: string,
  selectClassName?: string,
  handleInputChange?: (value: any) => void,
  isNarrow?: boolean,
  usePortal?: boolean,
  supportHighlighting?: boolean,
  scrollableParentElements?: HTMLElement[],
  creatable?: boolean,
  bodyElement?: HTMLElement,
  meta?: {
    dirty?: boolean,
    touched?: boolean,
    error?: string,
    submitError?: string,
    dirtySinceLastSubmit?: boolean,
  },
  components?: SelectComponentsConfig<any, boolean, any>,
  options: OptionsOrGroups<any, any>,
  simpleValue?: boolean,
  isSlim?: boolean,
  isSelectedPlaceholderVisibleWhenOpen?: boolean,
  isLoading?: boolean,
  isMulti?: boolean,
  disabled?: boolean,
  searchable?: boolean,
  clearable?: boolean,
  backspaceRemovesValue?: boolean,
  onChange: (value: OnChangeValue<any, boolean>, meta?: ActionMeta<any>) => void,
  dataTest: string,
  maxMenuHeight?: number,
  menuPlacement?: MenuPlacement,
  selectStyles?: Props<any, boolean, GroupBase<any>>['styles'],
  multiValueClassName?: string,
  getMenuOuterStyle: (defaultStyle: () => React.CSSProperties, container: HTMLDivElement) => React.CSSProperties,
  hasOneOption?: boolean,
  closePortalMenuOnScroll?: boolean,
  isErrorMessage?: boolean,
  isValidateOnChange?: boolean,
  onOpen?: () => void,
  onClose?: () => void,
  tooltipDisabled?: boolean,
  tooltipOptions?: { [key: string]: any },
}

const defaultProps: Partial<IProps> = {
  name: 'select',
  className: '',
  usePortal: false,
  supportHighlighting: true,
  backspaceRemovesValue: true,
  clearable: true,
  maxMenuHeight: 200,
  selectStyles: {},
  hasOneOption: false,
  closePortalMenuOnScroll: false,
  isErrorMessage: true,
  tooltipDisabled: true,
};

/**
* Class component is used because a lot of not obvious places where this component is used
* and "components" property is passed without memoization, so it will recreate renderers for any render, that
* will cause unexpected errors.
* In class component we have the same link for renderers, doesn't matter how "components" property is passed.
* @TODO: Rewrite all our select components to functional style and fix all places where it is used.
* @deprecated This component is DEPRECATED. Use ui/shared/components/Form/Select
*/
class Select extends Component<IProps, { isSelectMenuOpen: boolean }> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps: Partial<IProps>;

  constructor(props) {
    super(props);

    this.inputSearchValue = null;
    this.selectWrapperRef = createRef();
    this.selectRef = createRef();
    this.updateHandler = throttle(() => this.forceUpdate(), 50);
    this.state = {
      isSelectMenuOpen: false,
    };
    this.onMenuOpen = this.onMenuOpen.bind(this);
    this.onMenuClose = this.onMenuClose.bind(this);
    this.scrollHandler = this.scrollHandler.bind(this);
  }

  componentDidMount() {
    if (this.props.usePortal && this.props.scrollableParentElements) {
      this.props.scrollableParentElements.forEach((ref) => ref?.addEventListener('scroll', this.scrollHandler));
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.usePortal && this.props.scrollableParentElements && (
      !isEqual(this.props.scrollableParentElements, prevProps.scrollableParentElements)
      || this.props.closePortalMenuOnScroll !== prevProps.closePortalMenuOnScroll
    )) {
      this.props.scrollableParentElements.forEach((ref) => ref?.addEventListener('scroll', this.scrollHandler));
    }
  }

  componentWillUnmount() {
    if (this.props.usePortal && this.props.scrollableParentElements) {
      this.props.scrollableParentElements.forEach((ref) => ref?.removeEventListener('scroll', this.scrollHandler));
    }
  }

  inputSearchValue: string;

  selectWrapperRef: RefObject<HTMLDivElement>;

  selectRef: RefObject<any>;

  updateHandler: () => void;

  onMenuOpen() {
    this.setState({
      isSelectMenuOpen: true,
    });

    if (typeof this.props.onOpen === 'function') {
      this.props.onOpen?.();
    }
  }

  onMenuClose() {
    this.setState({
      isSelectMenuOpen: false,
    });

    if (typeof this.props.onClose === 'function') {
      this.props.onClose?.();
    }
  }

  scrollHandler() {
    if (this.props.closePortalMenuOnScroll) {
      this.onMenuClose();
    } else {
      this.updateHandler();
    }
  }

  getMenuOuterStyle = () => {
    const offset = getOffset(this.selectWrapperRef.current, this.props.bodyElement);
    const style = {
      position: 'fixed',
      margin: `${ offset.height }px 0 0 0`,
      width: offset.width,
      left: offset.left,
      top: offset.top,
    };

    if (this.props.bodyElement && (offset.top + 230 > this.props.bodyElement.offsetHeight)) {
      style.top = offset.top - 200;
      style.margin = '0 0 30px';
    }

    return style as React.CSSProperties;
  };

  getDataTest = (label) => {
    const { dataTest } = this.props;
    const dataLabel = label && typeof label === 'string' && label.toLowerCase().replace(/\W+/g, '_');
    return dataLabel ? `${ dataTest }_${ dataLabel }` : dataTest;
  };

  onInputChange = (value) => {
    if (this.inputSearchValue !== value) {
      this.inputSearchValue = value;

      if (this.props.handleInputChange) {
        this.props.handleInputChange(value);
      }
    }
  };

  formatOptionLabel = ({ label }) => {
    const { supportHighlighting } = this.props;
    const { isSelectMenuOpen } = this.state;
    const dataTestAttr = this.getDataTest('option');

    return supportHighlighting && isSelectMenuOpen ? (
      <span data-test={ dataTestAttr } className="Select-menu-list-option">
        <Highlighter
          autoEscape
          searchWords={ [this.inputSearchValue] }
          textToHighlight={ label?.toString() }
          highlightClassName="matchingText"
        />
      </span>
    ) : (label);
  };

  // TODO: must be replaced with a component from ui/shared/components/Select/components
  getOptionRenderer: ComponentType<OptionProps<any, boolean, any>> = (option) => {
    const { isSelected, data: { isDisabled, className } } = option;
    const dataTestAttr = this.getDataTest('option');
    const { children, ...props } = option;

    if (this.props.components?.Option) {
      const { Option } = this.props.components;
      return (
        <span
          data-test={ dataTestAttr }
          className={ cn('Select-menu-list-option', className, {
            'is-selected': isSelected,
            'is-disabled': isDisabled,
          }) }
        >
          <Option { ...props }>{ children }</Option>
        </span>
      );
    }
    return (
      <span
        data-test={ dataTestAttr }
        className={ cn('Select-menu-list-option', className, {
          'is-selected': isSelected,
          'is-disabled': isDisabled,
          [this.props.selectedOptionClassName]: this.props.selectedOptionClassName && isSelected,
        }) }
      >
        <ReactSelectComponents.Option
          { ...props }
          className={ this.props.optionClassName }
        >
          { children }
        </ReactSelectComponents.Option>
      </span>
    );
  };

  // TODO: must be replaced with a component from ui/shared/components/Select/components
  getMenuRenderer: ComponentType<MenuProps<any, boolean, any>> = (props) => {
    const { children, ...otherProps } = props;
    const dataTestAttr = this.getDataTest('options');
    if (this.props.usePortal) {
      return (
        <PortalWrp portalId={ PortalId.PORTAL_OVERLAY_ID }>
          <div className={ styles.selectPortalEncapsulation }>
            <div
              className="Select-menu-outer"
              style={
                typeof this.props.getMenuOuterStyle === 'function'
                  ? this.props.getMenuOuterStyle(this.getMenuOuterStyle, this.selectWrapperRef.current)
                  : this.getMenuOuterStyle()
              }
            >
              <div className="Select-menu">
                <span data-test={ dataTestAttr }>
                  <ReactSelectComponents.Menu { ...otherProps }>
                    { children }
                  </ReactSelectComponents.Menu>
                </span>
              </div>
            </div>
          </div>
        </PortalWrp>
      );
    }

    return (
      <div data-test={ dataTestAttr } className="Select-menu-wrapper">
        <ReactSelectComponents.Menu { ...otherProps }>
          { children }
        </ReactSelectComponents.Menu>
      </div>
    );
  };

  // TODO: must be replaced with a component from ui/shared/components/Select/components
  getValueRenderer: ComponentType<SingleValueProps<any, boolean, any>> = (props) => {
    const { children, ...otherProps } = props;
    const { data: { className } } = props;
    const { isSelectedPlaceholderVisibleWhenOpen, selectedPlaceholderWhenOpen } = this.props;
    const { isSelectMenuOpen } = this.state;

    if (this.props.components?.SingleValue) {
      const { SingleValue } = this.props.components;
      return (
        <div
          data-test={ this.getDataTest('selected') }
          className={ className }
          style={ props.getStyles('singleValue', props) as React.CSSProperties }
        >
          <SingleValue { ...otherProps }>{ children }</SingleValue>
        </div>
      );
    }

    if (isSelectedPlaceholderVisibleWhenOpen && isSelectMenuOpen) {
      return (
        <div
          data-test={ this.getDataTest('selected') }
          className={ props.getClassNames('singleValue', props) }
          style={ props.getStyles('singleValue', props) as React.CSSProperties }
        >
          <ReactSelectComponents.SingleValue { ...otherProps }>
            <span className={ styles.placeholder }>{ selectedPlaceholderWhenOpen }</span>
          </ReactSelectComponents.SingleValue>
        </div>
      );
    }

    return (
      <div
        data-test={ this.getDataTest('selected') }
        className={ props.getClassNames('singleValue', props) }
        style={ props.getStyles('singleValue', props) as React.CSSProperties }
      >
        <ReactSelectComponents.SingleValue { ...otherProps }>{ children }</ReactSelectComponents.SingleValue>
      </div>
    );
  };

  onChangeHandler = (value, props) => {
    if (this.props.simpleValue) {
      this.props.onChange(value?.value || null);
    } else {
      this.props.onChange(value, props);
    }
  };

  // TODO: must be replaced with a component from ui/shared/components/Select/components
  valueContainer: ComponentType<ValueContainerProps<any, boolean, any>> = (props) => {
    const { children, ...otherProps } = props;
    const { components = {}, isLoading, isSlim } = this.props;
    let cut = 0;
    if (isLoading) {
      cut += 21;
    }
    if (!components.DropdownIndicator) {
      cut += 20;
    }
    const width = `calc(100% - ${ cut }px)`;
    const lineHeight = isSlim ? '16px' : '26px';
    const styleOverride = {
      padding: 0,
      width,
      lineHeight,
    };

    if (this.props.components?.ValueContainer) {
      const { ValueContainer } = this.props.components;
      return (
        <div
          data-test={ this.getDataTest('value_container') }
          style={ {
            ...props.getStyles('valueContainer', props),
            ...styleOverride,
          } as React.CSSProperties }
        >
          <ValueContainer { ...otherProps }>{ children }</ValueContainer>
        </div>
      );
    }
    return (
      <div
        data-test={ this.getDataTest('value_container') }
        style={ {
          ...props.getStyles('valueContainer', props),
          ...styleOverride,
        } as React.CSSProperties }
      >
        <ReactSelectComponents.ValueContainer { ...otherProps }>{ children }</ReactSelectComponents.ValueContainer>
      </div>
    );
  };

  // TODO: must be replaced with a component from ui/shared/components/Select/components
  getMultiValueRenderer: ComponentType<MultiValueProps<any, boolean, any>> = ({
    children,
    ...props
  }) => {
    const { multiValueClassName } = this.props;

    if (this.props.components?.MultiValue) {
      const { MultiValue } = this.props.components;
      return (
        <div
          data-test={ this.getDataTest('selected') }
          className={ multiValueClassName }
        >
          <MultiValue { ...props }>{ children }</MultiValue>
        </div>
      );
    }

    return (
      <div
        data-test={ this.getDataTest('selected') }
        className={ multiValueClassName }
      >
        <ReactSelectComponents.MultiValue { ...props }>{ children }</ReactSelectComponents.MultiValue>
      </div>
    );
  };

  getMultiValueRemoveRenderer: ComponentType<MultiValueRemoveProps<any, boolean, any>> = (props) => {
    if (props.selectProps?.value.length === 1 && this.props.hasOneOption) {
      return null;
    }

    return (
      <ReactSelectComponents.MultiValueRemove { ...props }>
        <Icon type={ IconType.cancel } className={ styles.multiValueRemoveIcon } />
      </ReactSelectComponents.MultiValueRemove>
    );
  };

  // TODO: must be replaced with a component from ui/shared/components/Select/components
  getInput = (props) => (
    <ReactSelectComponents.Input
      { ...props }
      name={ this.props.name }
      data-lpignore
    />
  );

  render() {
    const {
      name,
      meta,
      value: inputVal,
      label,
      className,
      isNarrow,
      selectClassName,
      formFieldClassName,
      creatable,
      clearable,
      disabled,
      searchable,
      simpleValue,
      components = {},
      isMulti,
      isSelectedPlaceholderVisibleWhenOpen,
      onChange,
      dataTest,
      isSlim,
      supportHighlighting,
      selectStyles,
      isErrorMessage,
      isValidateOnChange,
      tooltipDisabled,
      tooltipOptions,
      ...otherProps
    } = this.props;
    const metaOnChangeProperty = isValidateOnChange ? meta?.dirty : meta?.touched;
    const isError = meta && metaOnChangeProperty && (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit));
    const customSelectStyles = {
      ...(isSlim ? customStylesSlim : customStyles),
      ...selectStyles,
    };
    // TODO need refactoring in the future. This is not good implementation.
    let value = isMulti ? inputVal : otherProps.options.filter(({ value }) => {
      if (inputVal && typeof inputVal === 'object') {
        return value === inputVal.value;
      }
      return value === inputVal;
    });
    if (inputVal && inputVal.length && isMulti && creatable) {
      value = inputVal.map((item) => (
        { ...item, value: item.id || item.value, label: item.domain || item.name || item.label }));
    }
    if (isEmpty(value) && !isEmpty(inputVal)) {
      value = inputVal;
    }

    return (
      <FormField
        isNarrow={ isNarrow }
        isValidationFeedback={ !!isError }
        dataTest={ dataTest }
        className={ formFieldClassName }
      >
        <div
          className={ cn(className, styles.selectEncapsulation) }
          ref={ this.selectWrapperRef }
        >
          { label && (
            <Label>{ label }</Label>
          ) }
          <Tooltip
            disabled={ tooltipDisabled }
            { ...tooltipOptions }
          >
            { !creatable && (
              <ReactSelect
                filterOption={ createFilter({ ignoreCase: true, stringify: (option) => option.label.toString() }) }
                className={ cn(['Select', selectClassName, { isError }]) }
                onInputChange={ this.onInputChange }
                onChange={ this.onChangeHandler }
                components={ {
                  ...components,
                  Menu: this.getMenuRenderer,
                  Option: this.getOptionRenderer,
                  Input: this.getInput,
                  ValueContainer: this.valueContainer,
                  SingleValue: this.getValueRenderer,
                  MultiValue: this.getMultiValueRenderer,
                  MultiValueRemove: this.getMultiValueRemoveRenderer,
                  LoadingIndicator: () => <Spinner className={ styles.selectSpinner } />,
                } }
                ref={ this.selectRef }
                theme={ selectTheme }
                styles={ customSelectStyles }
                noOptionsMessage={ () => 'No results found' }
                formatOptionLabel={ this.formatOptionLabel }
                value={ value }
                isClearable={ clearable }
                isDisabled={ disabled }
                isSearchable={ searchable }
                isMulti={ isMulti }
                onMenuOpen={ this.onMenuOpen }
                onMenuClose={ this.onMenuClose }
                menuIsOpen={ this.state.isSelectMenuOpen }
                { ...otherProps }
              />
            ) }
            { creatable && (
              <CreatableSelect
                className={ cn(['Select', selectClassName, { isError }]) }
                filterOption={ createFilter({ ignoreCase: true, stringify: (option) => option.label.toString() }) }
                isClearable={ clearable }
                formatOptionLabel={ this.formatOptionLabel }
                theme={ selectTheme }
                styles={ customSelectStyles }
                onChange={ this.onChangeHandler }
                value={ value }
                isDisabled={ disabled }
                isSearchable={ searchable }
                isMulti={ isMulti }
                components={ {
                  ...components,
                  Menu: this.getMenuRenderer,
                  Input: this.getInput,
                  Option: this.getOptionRenderer,
                  ValueContainer: this.valueContainer,
                  SingleValue: this.getValueRenderer,
                  MultiValue: this.getMultiValueRenderer,
                  MultiValueRemove: this.getMultiValueRemoveRenderer,
                } }
                onMenuOpen={ this.onMenuOpen }
                onMenuClose={ this.onMenuClose }
                menuIsOpen={ this.state.isSelectMenuOpen }
                { ...otherProps }
              />
            ) }
          </Tooltip>
          { isErrorMessage && <FormError { ...meta } touched={ metaOnChangeProperty } /> }
        </div>
      </FormField>
    );
  }
}

Select.defaultProps = defaultProps;

export default Select;
