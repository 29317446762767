import { useMemo, useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';
import ReviewModeService from '@/dealroadshow/application/ReviewModeService';
import NotificationManager from '@/Framework/Notification/NotificationManager';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import AskAQuestionRepository from '@/dealroadshow/infrastructure/repository/AskAQuestionRepository';
import RoadshowType from '@/dealroadshow/domain/RoadshowType';

const useRoadshow = () => {
  const { container } = useDIContext();
  const roadshowRepository = container.get<RoadshowRepository>(RoadshowRepository);
  const askAQuestionRepository = container.get<AskAQuestionRepository>(AskAQuestionRepository);
  const viewerReviewModeService = container.get<ReviewModeService>(ReviewModeService);

  const [roadshow, setRoadshow] = useState<any>({});
  const [roadshowId, setRoadshowId] = useState(null);
  const [entryCode, setEntryCode] = useState(null);

  const getRetailRoadshowById = async (roadshowId: string) => {
    try {
      const roadshow = await roadshowRepository.getPresentableRetailRoadshowById(roadshowId);
      setRoadshow(roadshow);
      setRoadshowId(roadshowId);
      return roadshow;
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
      throw error;
    }
  };

  const getPreviewRoadshowById = async (roadshowId: string) => {
    try {
      const roadshow = await roadshowRepository.getPreviewPresentableRoadshowById(roadshowId);
      setRoadshow(roadshow);
      setRoadshowId(roadshowId);
      return roadshow;
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
      throw error;
    }
  };

  const getRoadshowById = async (roadshowId: string) => {
    try {
      const roadshow = await roadshowRepository.getPresentableRoadshowById(roadshowId);
      setRoadshow(roadshow);
      setRoadshowId(roadshowId);
      return roadshow;
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
      throw error;
    }
  };

  const getRoadshowByEntryCode = async (entryCode: string, isRetail: boolean) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const roadshow = await roadshowRepository.getPresentableRoadshowByEntryCode(entryCode);
      setRoadshow(roadshow);
      setRoadshowId(roadshow.id);
      setEntryCode(entryCode);
      subscribeOnRoadshowUpdate(roadshow.id, isRetail);
      return roadshow;
    } catch (error) {
      throw error;
    }
  };

  const subscribeOnRoadshowUpdate = async (roadshowId: string, isRetail = false) => {
    try {
      const askAQuestion = await askAQuestionRepository.subscribeOnRoadshowUpdate({
        roadshowId,
        onShowButton:
          ({ investorQuestionsEnabled }) => setRoadshow((prevState) => ({ ...prevState, investorQuestionsEnabled })),
        isPublic: isRetail,
      });
      return askAQuestion;
    } catch (err) {
      NotificationManager.error(getErrorMessage(err));
      throw err;
    }
  };

  const isModerated = roadshow.moderated;
  const isSlidesAndVideo = RoadshowType.isSlidesAndVideo(roadshow.type);
  const isSlidesAndAudio = RoadshowType.isSlidesAndAudio(roadshow.type);
  const isVideoOnly = RoadshowType.isVideoOnly(roadshow.type);
  const isLiveVideo = RoadshowType.isLiveVideo(roadshow.type);
  const isSlides = RoadshowType.isSlides(roadshow.type);
  const hasPresentationMedia = RoadshowType.hasPresentationMedia(roadshow.type);

  return useMemo(() => ({
    roadshow,
    entryCode,
    roadshowId,
    isModerated,
    isSlidesAndVideo,
    isSlidesAndAudio,
    isVideoOnly,
    isLiveVideo,
    isSlides,
    hasPresentationMedia,
    getRetailRoadshowById,
    getPreviewRoadshowById,
    getRoadshowById,
    getRoadshowByEntryCode,
    setReviewMode: viewerReviewModeService.setReviewMode,
    getReviewMode: viewerReviewModeService.getReviewMode,
    clearReviewMode: viewerReviewModeService.clearReviewMode,
  }),
  [roadshow, roadshowId, entryCode]);
};

export default useRoadshow;
