import { Dependencies } from 'constitute';
import SessionStorageRepository from '@/Framework/browser/storage/SessionStorageRepository';

const REVIEW_SESSION_STORAGE_KEY = 'isViewerReviewMode';

@Dependencies(SessionStorageRepository)
class ReviewModeService {
  private currentUserId: number;

  constructor(
    protected sessionStorageRepo: SessionStorageRepository,
  ) {}

  private get storageKey(): string {
    return `${ REVIEW_SESSION_STORAGE_KEY }.${ this.currentUserId }`;
  }

  setReviewMode = (isReviewMode: boolean, userId: number): void => {
    if (!userId) {
      // eslint-disable-next-line no-console
      console.error('Cannot set viewerReviewMode, no userId provided');
      return;
    }
    this.currentUserId = userId;

    let viewerReviewMode = this.getReviewMode(userId);

    if (viewerReviewMode) {
      // eslint-disable-next-line no-console
      console.warn(`Warning! Cannot set viewerReviewMode, it's already has. Current viewerReviewMode: ${ viewerReviewMode }`);
      return;
    }

    if (!viewerReviewMode) {
      this.sessionStorageRepo.setItem(this.storageKey, isReviewMode);
    }
  };

  getReviewMode = (userId: number): boolean => {
    this.currentUserId = userId;
    if (!userId) {
      return false;
    }
    return !!JSON.parse(this.sessionStorageRepo.getItem(this.storageKey));
  };

  clearReviewMode = (userId?: number): void => {
    if (userId) {
      this.currentUserId = userId;
    }
    this.sessionStorageRepo.removeItem(this.storageKey);
  };
}

export default ReviewModeService;
